
// export const paymentDetails = {
//     gpay:"fcbizprkqpv@freecharge",
//     paytm:"fcbizprkqpv@freecharge",
//     phonepe:"fcbizprkqpv@freecharge",
//     upi:"fcbizprkqpv@freecharge"
// }

export const paymentDetails = {
    gpay:"fcbizprkqpv@freecharge",
    paytm:"fcbizprkqpv@freecharge",
    phonepe:"fcbizprkqpv@freecharge",
    upi:"fcbizprkqpv@freecharge"
}